/* Fonts */
@font-face {
  font-family: "SFPro";
  src: url("fonts/SFPro/SF-Pro-Text-Regular.otf");
}

@font-face {
  font-family: "SFCompact";
  src: url("fonts/SFCompact/SF-Compact.ttf");
}

@font-face {
  font-family: "NY";
  src: url("fonts/NewYork/NewYork.ttf");
}

@font-face {
  font-family: "SFMono";
  src: url("fonts/SFMono/SF-Mono.ttf");
}

@font-face {
  font-family: "MarketDeco";
  src: url("fonts/market_deco/Market_Deco.ttf");
}

body {
  margin: 0;
  font-family: "SFPro", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "SFMono", Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
p,
code {
  color: #000;
}

.mono-field {
  font-family: "SFMono", Menlo, Monaco, Consolas, "Courier New", monospace;

  width: 2rem;
  text-align: center;
}

.name-field {
  width: 6rem;
  text-align: left;
}